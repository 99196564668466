/*******************/
/*    MESSAGES     */
/*******************/

div.messages {
	margin-top:50px;
}

div.messages a:link,
div.messages a:visited,
.help a:link,
.help a:visited,
.help .more-help-link a:link,
.help .more-help-link a:visited {
  color: #0066B3;
}

div.messages a:hover,
div.messages a:active,
.help a:hover,
.help a:active,
.help .more-help-link a:hover,
.help .more-help-link a:active {
  color: #000;
}

div.messages,
div.messages.status {
  background-color: #EAF8E4;
  border: 2px solid #8DC478;
  color: #3E642F;
  font-weight: 600;
  font-size: 1em;
  margin-bottom: 20px;
  overflow: hidden;
  padding: 14px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}

div.messages.warning {
  background: #FFFFDD;
  border: 2px solid #EED300;
  color: #745500;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}

div.messages ul {
  list-style-position: inside;
}
div.messages em {
  color: #555;
}
div.error {
  background-color: #FFF2F2;
  border-color: #CC0000;
  color: #B20000;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}


label {font-weight: bold}

.sidecontent {
    float:right;
    font-size: 110%;
    width: 300px;

	padding: 14px;
	background: #FFFFDD;
	border: 2px solid #EED300;
	color: #745500;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
}