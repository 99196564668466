// ==========================================================================
// Base – Helpers
// ==========================================================================

// General Resets
.no-margin { margin: 0; }
.no-padding { padding: 0; }
.no-float { float: none; }
.no-background { background: transparent; }
.no-border { border: 0; }

// Font Weights
.font-thin { font-weight: $font-thin; }
.font-light { font-weight: $font-light; }
.font-regular { font-weight: $font-regular; }
.font-medium { font-weight: $font-medium; }
.font-semi-bold { font-weight: $font-semi-bold; }
.font-bold { font-weight: $font-bold; }
.font-extra-bold { font-weight: $font-extra-bold; }
.font-ultra-bold { font-weight: $font-ultra-bold; }

// Font Styles
.font-normal { font-style: normal; }
.font-italic { font-style: italic; }

// Text Modifications
.uppercase { text-transform: uppercase; }
.lowercase { text-transform: lowercase; }
.capitalize { text-transform: capitalize; }

// Text Alignments
.text-left { text-align: left; }
.text-right { text-align: right; }
.text-center { text-align: center; }
.text-justify { text-align: justify; }

// Positions
.relative { position: relative; }
.absolute { position: absolute; }
.static { position: static; }

// Display Types
.block { display: block; }
.inline-block { display: inline-block; }
.inline { display: inline; }
.none, .hide { display: none; }

// Floats
.left { float: left; }
.right { float: right; }

// Alignments
.center-element {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

// Hide only visually, but have it available for screenreaders: : h5bp.com/v
.sr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Lists
.list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
  }
}
.list-inline {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
    display: inline-block;
  }
}

// Image Positions
.img-left {
  float: left;
  margin-right: 20px;
}
.img-right {
  float: right;
  margin-left: 20px;
}

// Grouped Fields
.field-group {
  padding-top: .5em;
  padding-bottom: .5em;
  overflow: hidden;
  label {
    padding-top: 8px;
  }
}
.field { width: 100%; }

// Disable Element
.disabled {
  pointer-events: none;
  opacity: .5;
}

// Checkbox and Radio
.checkbox,
.radio {
  display: inline-block;
  position: relative;
  margin-top: .5em;
  margin-bottom: .5em;
  label {
    padding-left: 20px;
    padding-top: 0;
    display: inline-block;
  }
  input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    top: 4px;
    left: 0;
  }
}

// Animations
.animate {
  @include animate(1s);
}
.animate-infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}