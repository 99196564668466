// ==========================================================================
// Base – Assets
// ==========================================================================

// Custom Buttons
.button {
  border: 1px solid #d7d7d7;
  background-color: #f3f3f3;
  line-height: normal;
  padding: .5em 1em;
  text-decoration: none;
  color: #363636;
  display: inline-block;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  &:hover, &:active { text-decoration: none; }
  &:hover { background: #f9f9f9; }
}
.button-primary {
  // Add your styles here
}
.button-secondary {
  // Add your styles here
}

// Button Styled as Link
.button-link {
  color: $base-link-color;
  text-decoration: underline;
  border: 0;
  background: transparent;
  padding: 0;
  &:hover { text-decoration: none; }
  &:active { outline: 0; }
}

// Custom Select
.select {
  border: 1px solid #ccc;
  overflow: hidden;
  height: 36px;
  line-height: 32px;
  padding: 0 .4em;
  position: relative;
  display: block;
  &:before {
    content: "";
    border: 6px solid transparent;
    border-top-color: #676767;
    top: 50%;
    right: 10px;
    margin-top: -3px;
    pointer-events: none;
    position: absolute;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 120%;
    height: 36px;
    padding: 0;
    line-height: normal;
    border: 0;
    background: transparent;
    display: block;
    &:focus { outline: 0; }
  }
}