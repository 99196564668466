/*
 * Common styles
 */


@mixin common($param: '') {

    h2 {
        margin-bottom: 0.8em;
    }
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }

    .node {
        form {
            input.form-text {
                width: 100%;
            }
            input[name=to_display], input[name=from_display] {
                width: auto !important;
            }
        }
    }

    /* NAVIGATION */
    #block-system-main-menu .content,
    #block-system-main-menu .content ul,
    #block-system-main-menu .content ul li,
    #block-system-main-menu .content ul li a,
    #block-system-main-menu .content #menu-button {
        margin: 0;
        padding: 0;
        border: 0;
        list-style: none;
        line-height: 1;
        display: block;
        position: relative;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    #block-system-main-menu .content:after,
    #block-system-main-menu .content > ul:after {
        content: ".";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0;
    }
    #block-system-main-menu .content {
        width: auto;
        font-family: Raleway, sans-serif;
        line-height: 1;
    }
    #block-system-main-menu .content > ul {
        background: #fff;
    }
    #block-system-main-menu .content > ul > li {
        float: left;
    }
    #block-system-main-menu .content.align-center > ul {
        font-size: 0;
        text-align: center;
    }
    #block-system-main-menu .content.align-center > ul > li {
        display: inline-block;
        float: none;
    }
    #block-system-main-menu .content.align-right > ul > li {
        float: right;
    }
    #block-system-main-menu .content > ul > li > a {
        padding: 6px 20px;
        margin-bottom: 10px;
        margin-top: 10px;
        font-size: 14px;
        color: #565A5C;
        letter-spacing: 1px;
        text-decoration: none;
        text-transform: lowercase;
        background: #fff;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
    }
    #block-system-main-menu .content > ul > li > a.active {
        color: #000;
        font-weight: bold;
    }

	/* small-screen specifics */
    #block-system-main-menu .content.small-screen {
        width: 100%;
    }
    #block-system-main-menu .content.small-screen > ul,
    #block-system-main-menu .content.small-screen.align-center > ul {
        width: 100%;
        text-align: left;
    }
    #block-system-main-menu .content.small-screen > ul > li,
    #block-system-main-menu .content.small-screen.align-center {
        float: none;
        display: block;
        border-top: 1px solid rgba(100, 100, 100, 0.1);
    }
    #block-system-main-menu .content.small-screen #menu-button {
        display: block;
        padding: 16px 20px;
        cursor: pointer;
        font-size: 14px;
        text-decoration: none;
        color: #333;
        text-transform: uppercase;
        letter-spacing: 1px;
        background: #fff;
    }
    #block-system-main-menu .content.small-screen #menu-button:after {
        content: "";
        position: absolute;
        right: 20px;
        top: 17px;
        display: block;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
        border-top: 2px solid #333;
        border-bottom: 2px solid #333;
        width: 22px;
        height: 3px;
    }
    #block-system-main-menu .content.small-screen #menu-button.menu-opened:after {
        border-top: 2px solid #333;
        border-bottom: 2px solid #333;
    }
    #block-system-main-menu .content.small-screen #menu-button:before {
        content: "";
        position: absolute;
        right: 20px;
        top: 27px;
        display: block;
        width: 22px;
        height: 2px;
        background: #333;
    }
    #block-system-main-menu .content.small-screen #menu-button.menu-opened:before {
        background: #333;
    }



	.visual {
		position: relative;
		height: 360px;
		text-align: center;
		z-index: 1;

		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;

		img {
			margin: 0 auto;
			overflow: hidden;
			height: 500px;
		}
	}
	.node {
		position: relative;
		background-color: #fff;
		z-index: 10;
		padding: 30px 50px;
	}


    // footer
    .l-footer {
        color: #fff;
        letter-spacing: 1px;
        font-size: 13px;
        .footer {
            background-color: #646978;
            padding: 30px 0 16px;
            a {
                color: #fff;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .copyright {
            background-color: #272b38;
            padding: 12px 0;
        }
    }

    .col-4 {
        img {
            max-width: 100%;
        }
    }


    // booking
    .field-type-availability-calendar-booking-formlet .form-submit, .button-primary {
        position: relative;
        background-color: #5bc0de;
        border-color: #46b8da;
        border: 1px solid transparent;
        border-radius: 4px;
        color: #FFF;
        cursor: default;
        box-shadow: none;
        padding: 6px 12px;
        vertical-align: middle;
        font-weight: bold;
        &:hover {
            color: #fff;
            background-color: #31b0d5;
            border-color: #269abc;
            text-decoration: none;
        }
        &:focus {
            color: #fff;
            background-color: #31b0d5;
            border-color: #1b6d85;
        }
    }
    .acbf-date .form-reset {
        width: auto;
    }


    // media gallery images
    .gallery-thumb-inner {
        img {
            max-width: 100%;
            height: auto;
            max-height: 100%;
        }
    }
    .mg-col-4 > .field-items > .field-item, .mg-col-4 .mg-gallery {
        width: 49.9% !important;
    }
    #cboxContent {
        button {
            &:focus {
                outline:0;
            }
        }
    }
}



@mixin common-tablet($param: '') {

    /* NAVIGATION */
    #block-system-main-menu .content #menu-button {
        display: none;
    }
    #block-system-main-menu .content.small-screen > ul > li + li > a {
        border-left: none;
    }
    #block-system-main-menu .content > ul > li + li > a {
        border-left: 1px solid #D9D9D9;
    }
    #block-system-main-menu .content > ul > li > a.active {
        color: #000;
    }
    #block-system-main-menu .content > ul > li:hover > a,
    #block-system-main-menu .content > ul > li > a:hover {
        color: #000;
        text-decoration: underline;
    }

	.node {
        //margin-top: -160px;
		margin-top: -55px;
		border-radius: 5px;
		min-height: 250px;
		padding: 30px 30px;
	}

    // media gallery images
    .mg-col-4 > .field-items > .field-item, .mg-col-4 .mg-gallery {
        width: 24.96% !important;
    }

}



@mixin common-desktop($param: '') {

}



@mixin common-hd($param: '') {

}

